import styles from './TripTypeSelect.module.scss';
import cn from 'classnames';

export const TripTypeSelect = ({
  tripState,
  handleTripTypeChange,
  values,
  handleChange,
  viewMode,
}) => {
  return (
    <div className={styles.tripTypeSelectContainer}>
      <div
        className={cn(styles.tripStateItem, {
          [styles.active]: tripState === 'round_trip',
          [styles.smallerFont]: viewMode === 'sideSearch',
        })}
        onClick={() => handleTripTypeChange('round_trip', handleChange, values)}
      >
        Round trip
      </div>
      <div
        className={cn(styles.tripStateItem, {
          [styles.active]: tripState === 'one_way',
          [styles.smallerFont]: viewMode === 'sideSearch',
        })}
        onClick={() => handleTripTypeChange('one_way', handleChange, values)}
      >
        One way
      </div>
      <div
        className={cn(styles.tripStateItem, {
          [styles.active]: tripState === 'multi_city',
          [styles.smallerFont]: viewMode === 'sideSearch',
        })}
        onClick={() => handleTripTypeChange('multi_city', handleChange, values)}
      >
        Multi-city
      </div>
    </div>
  );
};
