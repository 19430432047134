export const isWebView = () => {
  if (typeof document !== 'undefined') {
    const standalone = window.navigator.standalone,
      userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);
    if (ios) {
      return !standalone && !safari;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
